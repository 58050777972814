import * as React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

export const Button = React.forwardRef(
  ({ isLoading, disabled, children, ...props }, ref) => {
    return (
      <ButtonStyled ref={ref} disabled={disabled} {...props}>
        {isLoading ? <Loader /> : children}
      </ButtonStyled>
    )
  },
)

const Loader = styled.span`
  height: 16px;
  width: 16px;

  flex-shrink: 0;

  animation: ${keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`} 1.5s linear infinite;

  border: 3px solid white;
  border-radius: 50%;
  border-right-color: transparent;
  transform-origin: center center;
`

export const ButtonNormalized = React.forwardRef((props, ref) => {
  return <ButtonNormalizedStyled ref={ref} type={'button'} {...props} />
})

const ButtonNormalizedStyled = styled.button`
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  outline: none;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  appearance: none;
`

const ButtonStyled = styled(ButtonNormalized)`
  padding: 14px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  transition: 225ms;
  background: #6580a2;

  &:hover {
    background: #557092;
  }
  &:disabled {
    background: #d6e2f2;
  }

  font-size: 15px;
  text-align: center;
  color: white;
  text-decoration: none;
`
