import { FORM_ERROR } from 'final-form'
import { Form as FinalForm, Field } from 'react-final-form'
import { Button } from '../uikit/Button'
import styled from '@emotion/styled'
import { Error as ErrorComponent } from '../uikit/Error'
import { FieldInput } from '../uikit/Input'
import { Layout } from '../Layout'
import { email, required } from '../validator'
import { useNavigate } from 'react-router-dom'
import { FieldRadioButtons } from '../uikit/RadioButtons'
import { fetch } from '../fetch'

export const Form = () => {
  const navigate = useNavigate()

  return (
    <Layout>
      <Container>
        <Title>Получите индивидуальную скидку 2% для Вашего клиента</Title>
        <Tip>на покупку квартиры в новом архитектурном проекте "1-й Химкинский".</Tip>
        <FinalForm
          onSubmit={async (body) => {
            try {
              const link = await fetch('certificate', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({...body, building:'1-й Химкинский'}),
              }).then((x) => x.text())
              navigate(`/certificate/${link}`)
            } catch {
              return { [FORM_ERROR]: 'Invalid data' }
            }
          }}
        >
          {({ handleSubmit, submitting, submitError }) => (
            <FormStyled onSubmit={handleSubmit}>
              <Field
                name={'agency'}
                label={'АГЕНТСТВО'}
                placeholder={'Наименование агентства'}
                component={FieldInput}
                validate={required}
              />
              <Field
                name={'fullName'}
                label={'ИМЯ'}
                placeholder={'Фамилия Имя Отчество'}
                component={FieldInput}
                validate={required}
              />
              <Field
                name={'phoneNumber'}
                label={'ТЕЛЕФОН'}
                placeholder={'+7 987 654-32-10'}
                component={FieldInput}
                validate={required}
                mask={'+7 9## ###-##-##'}
              />
              <Field
                name={'email'}
                label={'EMAIL'}
                placeholder={'agent@example.ru'}
                component={FieldInput}
                validate={email}
              />
              {/* <Field
                name={'building'}
                label={'ЖИЛОЙ КОМПЛЕКС'}
                options={[
                  {
                    name: '1-й Химкинский',
                    value: '1-й Химкинский',
                  },
                  {
                    name: '1-й Ясеневский',
                    value: '1-й Ясеневский',
                  },
                  {
                    name: '1-й Измайловский',
                    value: '1-й Измайловский',
                  },
                ]}
                component={FieldRadioButtons}
                validate={required}
              /> */}
              <Button type={'submit'} isLoading={submitting}>
                ПОЛУЧИТЬ СЕРТИФИКАТ
              </Button>
              <br />
              <ErrorComponent>{submitError}</ErrorComponent>
            </FormStyled>
          )}
        </FinalForm>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400&display=swap");

  font-family: "Open Sans", sans-serif;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  margin-bottom: 8px;

  font-weight: bold;
  font-size: 24px;
  line-height: 122%;
  color: #424143;
`
const Tip = styled.div`
  margin-bottom: 32px;

  color: #424143;
  font-size: 16px;
  line-height: 122%;
`
const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  & > :nth-of-type(n) {
    margin-bottom: 4px;
  }
`
