import React from 'react'
import styled from '@emotion/styled'
import { Error } from './Error'

const RadioButtons = React.forwardRef(
  (
    { error, label, className, options = [], value: valueProp, onChange },
    _,
  ) => {
    return (
      <Container className={className}>
        {label && <Label>{label}</Label>}
        <Buttons>
          {options.map(({ name, value }) => (
            <Button
              key={value}
              isSelected={value === valueProp}
              onClick={() => onChange(value)}
            >
              {name}
            </Button>
          ))}
        </Buttons>
        <Error>{error}</Error>
      </Container>
    )
  },
)
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Button = styled.div`
  padding: 15px 12px;

  flex-grow: 1;

  border: 1px solid #ddd;
  border-right: none;
  &:first-of-type {
    border-radius: 6px 0 0 6px;
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
    border-right: 1px solid #ddd;
  }

  user-select: none;
  cursor: pointer;
  color: #636363;
  font-size: 16px;
  font-weight: 500;
  transition: 225ms;
  &:hover {
    background: #f8f7f5;
  }
  ${({ isSelected }) =>
    isSelected
      ? `
  border-color: #414042;
  background: #F8F7F5;
  color: #414042;
  
  & + div {
    border-left-color: #414042;
  }
  &:last-child  {
    border-right-color: #414042;
  }
  `
      : ''}
`
const Buttons = styled.div`
  margin-bottom: 2px;

  display: flex;
`
const Label = styled.div`
  margin-bottom: 12px;

  color: #878787;
  font-size: 12px;
  letter-spacing: 0.72px;
`

export const FieldRadioButtons = React.forwardRef(
  ({ input, meta, ...props }, ref) => {
    return (
      <RadioButtons
        ref={ref}
        {...input}
        error={meta.touched ? meta.error : undefined}
        {...props}
      />
    )
  },
)
