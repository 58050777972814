import React from 'react'
import styled from '@emotion/styled'
import { Error } from './Error'
import { PatternFormat } from 'react-number-format'

const Input = React.forwardRef(
  (
    { error, label, className, type = 'text', mask, onChange, ...props },
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState(false)
    return (
      <Container className={className}>
        {label && <Label>{label}</Label>}
        {mask ? (
          <PatternFormat
            mask="_"
            allowEmptyFormatting={isFocused}
            format={mask}
            customInput={InputStyled}
            onValueChange={(values) => {
              onChange(values.value)
            }}
            {...props}
            onFocus={(event) => {
              setIsFocused(true)
              props.onFocus(event)
            }}
            onBlur={(event) => {
              setIsFocused(false)
              props.onBlur(event)
            }}
          />
        ) : (
          <InputStyled
            as={type === 'textarea' ? 'textarea' : undefined}
            style={
              type === 'textarea'
                ? { resize: 'vertical', maxHeight: '100px' }
                : undefined
            }
            ref={ref}
            type={type}
            onChange={onChange}
            {...props}
          />
        )}
        <Error>{error}</Error>
      </Container>
    )
  },
)
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const InputStyled = styled.input`
  margin-bottom: 6px;
  padding: 15px 12px;

  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 16px;
  color: #414042;

  &::placeholder {
    color: #717171;
  }
  &:hover {
    border-color: #717171;
  }
  &:focus {
    border-color: #414042;
    border-inline-end-width: 1px;
    outline: 0;
  }
`
const Label = styled.div`
  margin-bottom: 12px;

  color: #878787;
  font-size: 12px;
  letter-spacing: 0.72px;
`

export const FieldInput = React.forwardRef(({ input, meta, ...props }, ref) => {
  return (
    <Input
      ref={ref}
      {...input}
      error={meta.touched ? meta.error : undefined}
      type={input.type}
      {...props}
    />
  )
})
