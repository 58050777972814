export const required = (value) =>
  !value || value.length === 0 ? 'Required' : undefined
export const email = (value) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value,
  )
    ? undefined
    : 'Not correct email'

export const minCountChar = (min) => (value) =>
  value?.length < min ? `Char should be greater ${min}` : undefined

export const composeValidators =
  (...validators) =>
  (value, allValue) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValue),
      undefined,
    )
