import styled from '@emotion/styled'

export const Error = styled.span`
  height: 17px;

  display: inline-flex;

  color: #e54236;
  font-size: 14px;
`
