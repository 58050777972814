import React from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from '../Layout'
import styled from '@emotion/styled'
import { Button, ButtonNormalized } from '../uikit/Button'
import clipboardIcon from '../icon/clipboard.svg'

export const Result = () => {
  const { id } = useParams()

  const [isShowClipboard, setIsShowClipboard] = React.useState(false)
  const timerId = React.useRef(0)

  return (
    <Layout>
      <Title>Ваш сертификат готов!</Title>
      <Tip>Скачайте его прямо сейчас:</Tip>
      <ButtonDownload
        as={'a'}
        href={`${process.env.REACT_APP_API_URL}/certificate/${id}`}
      >
        СКАЧАТЬ ФАЙЛ СЕРТИФИКАТА
      </ButtonDownload>
      <Tip>Или сохраните ссылку, чтобы скачать позже:</Tip>
      <Link>
        {window.location.href}
        <ClipboardButton
          onClick={async () => {
            await navigator.clipboard.writeText(window.location.href)
            setIsShowClipboard(true)
            clearTimeout(timerId.current)
            timerId.current = setTimeout(() => setIsShowClipboard(false), 4000)
          }}
        >
          <img alt={'clipboard'} src={clipboardIcon} />
        </ClipboardButton>
      </Link>
      {isShowClipboard && 'Copied to clipboard'}
    </Layout>
  )
}

const ButtonDownload = styled(Button)`
  margin-bottom: 32px;
`
const Title = styled.div`
  margin-bottom: 32px;

  font-weight: bold;
  font-size: 24px;
  line-height: 122%;
  color: #424143;
`
const Tip = styled.div`
  margin-bottom: 12px;

  font-weight: bold;
  color: #424143;
`
const Link = styled.div`
  padding: 9px 42px 9px 12px;
  min-height: 40px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  position: relative;

  border-radius: 6px;
  border: 1px solid #717171;

  color: #717171;
  font-size: 12px;
`
const ClipboardButton = styled(ButtonNormalized)`
  width: 40px;

  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  border: 1px solid #717171;
`
