import { Form } from './screens/Form'
import { Routes, Route, Link } from 'react-router-dom'
import { Result } from './screens/Result'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Form />} />
      <Route path="/certificate/:id" element={<Result />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export default App

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}
