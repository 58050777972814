import React from 'react'
import styled from '@emotion/styled'
import logo from './logo.svg'
import { Link } from 'react-router-dom'

export const Layout = ({ children }) => {
  return (
    <Container>
      <HeaderWrapper>
        <Header>
          <LinkLogo to="/">
            <img alt={'logo'} src={logo} />
          </LinkLogo>
        </Header>
      </HeaderWrapper>
      <Content>{children}</Content>
    </Container>
  )
}

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;

  background: #f7f9fa;
`
const Header = styled.div`
  padding: 0 20px;
  height: 56px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;

  display: flex;
  align-items: center;
`
const LinkLogo = styled(Link)``
